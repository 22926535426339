<template>
    <div id="update_app-container" :class="$mq">
        <div class="update-content">
            <div class="header-img">
                <img class="header-andy" src="~@/../public/img/alexhead_04.png" alt="andy-header" />
            </div>
            <div class="title-update">
                {{ $t('popup.update.title') }}
            </div>
            <div class="title-description-update" v-if="!min">
                {{ $t('popup.update.message') }}
            </div>
            <div class="title-description-update" v-else>
                {{ $t('popup.update.message_min') }}
            </div>
            <div class="footer-actions-update">
                <div class="update-now" @click="download()">
                    {{ $t('popup.update.text_download') }}
                </div>
                <div class="update-later" v-if="!min" @click="updateLater()">
                    {{ $t('popup.update.update_later') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'

export default {
    props: {
        min: {
            type: undefined,
            default: false
        }
    },
    data() {
        return {
            title: i18n.t('popup.update.title'),
            message: i18n.t('popup.update.message'),
            textDownload: i18n.t('popup.update.text_download')
        }
    },
    computed: {},
    methods: {
        updateLater() {
            this.$emit('updateLater')
        },
        download() {
            var self = this

            if (this.$store.getters.getIsApp) {
                const app_android = this.$store.getters['login/getAppAndroid']

                if (app_android && app_android.trim() != '') {
                    self.$overlay.loading()
                    if (app_android.trim().match(/\.apk/gi)) {
                        var fileTransfer = new FileTransfer()
                        fileTransfer.download(
                            app_android.trim(),
                            cordova.file.externalDataDirectory + 'andy.apk',
                            function (entry) {
                                log('download complete')
                                log(entry)
                                entry.file(function (file) {
                                    log(file)
                                    cordova.plugins.fileOpener2.open(cordova.file.externalDataDirectory + 'andy.apk', 'application/vnd.android.package-archive', {
                                        error: function (e) {
                                            log(e)
                                            self.$overlay.hide()
                                            window.open(app_android.trim(), '_system')
                                        },
                                        success: function () {
                                            self.$overlay.hide()
                                            log('file opened successfully')
                                        }
                                    })
                                })
                            },
                            function (error) {
                                const app_android = this.$store.getters['login/getAppAndroid']
                                log(error)
                                self.$overlay.hide()
                                window.open(app_android.trim(), '_system')
                            }
                        )
                    } else {
                        self.$overlay.hide()
                        window.open(app_android.trim(), '_system')
                    }
                } else {
                    self.$overlay.hide()
                    cordova.plugins.market.open(process.env.VUE_APP_ID_APP_ANDROID)
                }
            } else {
                window.location.reload()
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#update_app-container {
    @include display-flex();
    @include justify-content(center);
    width: 100%;
    height: 100%;

    .update-content {
        @include display-flex();
        @include flex-direction(column);
        @include justify-content(space-between);
        @include align-items(center);
        @include align-self(center);
        max-width: 600px;
        height: 100%;
        max-height: 700px;
        padding: 14px;

        .header-img {
            margin-top: 40px;
            .header-andy {
                max-width: 150px;
            }
        }

        .title-update {
            @include font-size(30px);
            color: $main-dark;
            font-family: $text-bold;
            text-align: center;
            line-height: 40px;
            margin-bottom: 10px;

            @include portrait-tablet {
                @include font-size(40px);
            }
        }
        .title-description-update {
            @include font-size(m);
            color: $inactive-s80;
            font-family: $text;
            text-align: center;
            margin-bottom: 10%;
            @include portrait-tablet {
                @include font-size(ml);
            }
        }
        .footer-actions-update {
            .update-now {
                @include font-size(m);
                @include border-radius(4px);
                background-color: $main-dark;
                color: #fff;
                font-family: $text-bold;
                padding: 25px 50px;
                @extend .interaction;

                @include portrait-tablet {
                    @include font-size(ml);
                }
            }

            .update-later {
                @include font-size(m);
                color: $main-dark;
                font-family: $text;
                text-decoration: underline;
                text-align: center;
                margin-top: 40px;
                margin-bottom: 60px;
                @extend .interaction;
            }
        }
    }
    &.landscape {
        .update-content {
            .header-img {
                margin-top: 0px;
                .header-andy {
                    max-width: 200px;
                }
            }

            .title-update {
                @include font-size(40px);
            }
            .title-description-update {
                @include font-size(26px);
                line-height: 30px;
            }
            .footer-actions-update {
                .update-now {
                    @include font-size(22px);
                    padding: 30px 60px;
                }

                .update-later {
                    @include font-size(22px);
                }
            }
        }
    }

    &.desktop {
        .update-content {
            .header-img {
                margin-top: 0px;
                .header-andy {
                    max-width: 200px;
                }
            }

            .title-update {
                @include font-size(40px);
            }
            .title-description-update {
                @include font-size(26px);
                line-height: 30px;
            }
            .footer-actions-update {
                .update-now {
                    @include font-size(l);
                    padding: 30px 60px;
                }

                .update-later {
                    @include font-size(l);
                }
            }
        }
    }
}
</style>
