<template>
    <nav id="menu-top-portrait" :class="[{ offline: !conn }, { printer: printing }]">
        <!-- MENU ICON -->
        <div class="menu-icon" @click="toggleMenu" :class="{renew:renewLicense}"></div>

        <!-- OFFLINE ICON -->
        <div class="offline-icon" v-if="!conn && !printing"></div>

        <!-- CUSTOMER LOGO / CURRENT PAGE -->
        <template v-if="$route.name == 'Home'">
            <router-link class="customer-logo" :to="redirectPath('/home')" :style="{ backgroundImage: 'url(' + clientLogo + ')' }"></router-link>
        </template>

        <template v-else>
            <div class="title-menu" :class="{offline: !conn && !printing, sync: !printing && showSync}">
                <div class="sub-menu" v-if="$route.name == 'ConfigPage'" @click="SubMenuToggle()">
                    <div class="title">{{ $t('menu_tools.config_title') }}</div>
                    <div :class="{ opened: openedSubMenu }" class="sub-title">
                        <template v-if="$route.path == '/config/location'">{{ $t('config.location.title') }}</template>
                        <template v-else-if="$route.path == '/config/printer'">{{ $t('config.printer.title') }}</template>
                        <template v-else-if="$route.path == '/config/sensconfig'">{{ $t('config.sensor.title') }}</template>
                        <template v-else-if="$route.path == '/config/dashboard'">{{ $t('config.dashboard.title') }}</template>
                        <template v-else-if="$route.path == '/config/users'">{{ $t('config.users.title') }}</template>
                        <template v-else-if="$route.path == '/config/status'">{{ $t('config.status.title') }}</template>
                    </div>
                </div>

                <h5 v-else class="page-title" @click="toCurrentRoute">{{ pageTitle }}</h5>
            </div>
        </template>

        <!-- PRINTER ICON -->
        <div class="printer-icon" v-if="printing && !showSync" @click="openPrintQueue()"></div>

        <!-- SYNC -->
        <div class="sync-icon" v-else-if="!printing && showSync" @click="openStatusPage()"></div>

        <!-- USER -->
        <div class="user-avatar" v-if="user" @click="toggleUser">
            <img class="main-img" v-bind:src="user.avatar.includes('//') ? user.avatar : css_image_url + 'avatar/' + user.avatar" />
        </div>
    </nav>
</template>

<script>
import mixin from '@/core/mixin'

export default {
    components: {},
    mixins: [mixin],
    data() {
        return {}
    },
    computed: {
        emp_id() {
            return this.$store.getters['loginUser/getLocalEmployee']
        },
        printing() {
            return this.$store.getters['label/getIsPrinting']
        },

        user() {
            var employee = this.$store.getters['employee/getEmployee'](this.emp_id)

            if (employee) {
                return employee
            }
        },
        conn() {
            return this.$store.getters['getConnState']
        },

        clientLogo() {
            const location = this.$store.getters['login/getLocation']
            if (typeof location !== 'undefined') {
                return location.avatar
            } else {
                return ''
            }
        },

        pageTitle() {
            return this.$t('page_title.' + this.$route.name)
        },

        home() {
            return this.$store.getters['getHome'] ? this.$store.getters['getHome'] : '/home'
        },

        openedMenu() {
            return this.$store.state['menu_tools_is_expanded']
        },

        openedSubMenu() {
            return this.$store.state['submenu_tools_is_expanded']
        },

        showingUser() {
            return this.$store.state['menu_user_is_visible']
        },

        showSync() {
            return this.$store.getters['getSyncFlag']
        },
        renewLicense() {
            return this.$store.getters['getLicenseEndDate']
        },
    },
    methods: {
        // TOOLS
        toggleMenu() {
            if (this.openedMenu) {
                this.$store.commit('setMenuToolsExpanded', false)
            } else {
                this.$store.commit('setMenuToolsExpanded', true)
                this.$store.commit('setSubMenuToolsExpanded', false)
                this.$store.commit('setMenuUserVisible', false)
            }
        },

        SubMenuToggle() {
            if (this.openedSubMenu) {
                this.$store.commit('setSubMenuToolsExpanded', false)
            } else {
                this.$store.commit('setSubMenuToolsExpanded', true)
                this.$store.commit('setMenuToolsExpanded', false)
                this.$store.commit('setMenuUserVisible', false)
            }
        },

        // USER
        toggleUser() {
            if (this.showingUser) {
                this.$store.commit('setMenuUserVisible', false)
            } else {
                this.$store.commit('setMenuUserVisible', true)
                this.$store.commit('setSubMenuToolsExpanded', false)
                this.$store.commit('setMenuToolsExpanded', false)
            }
        },

        // GENERAL METHODS

        redirectPath(firstResult) {
            // console.log('redirect')
            return this.$root.redirectPath(firstResult)
        },

        toCurrentRoute() {
            let currentPath = this.$route.path
            let aux = this.$route.path.split('/')[1]
            let parentPath = typeof aux !== 'undefined' ? '/' + aux : false

            if (parentPath && parentPath != currentPath) {
                this.$router.push({ path: parentPath })
            }
        },

        openPrintQueue() {
            this.$router.push({ name: 'LabelQueue', params: { queue: 1 } }).catch((err) => {})
        },
        openStatusPage() {
            if(!fnCheckConnection()){
                this.$popup.alert({
                        message: this.$t('sync.sync_error_offline')
                })
                return
            }
            this.$router.push({ name: 'ConfigPage', params: { page: 'status' } }).catch((err) => {})
        }
    }
}
</script>

// PORTRAIT STYLES
<style lang="scss">
#menu-top-portrait {
    $menu-top-height: 60px;
    @include display-flex();
    @include align-items();
    @include justify-content(space-between);
    position: fixed;
    top: 0;
    right: 0;
    z-index: $z-menu-top;
    // z-index: 1899999;
    height: $menu-top-height;
    width: 100%;
    // transition-duration: 0.4s;
    background-color: $inactive-light;

    .menu-icon {
        @include background($image: img('menu_toggle.svg'), $size: 40px, $position: center center);
        height: 60px;
        width: 60px;
        cursor: pointer;
        padding: 10px;
        position: relative;

        &.renew{
            &::after{
                /* Notification img */
                @include background($image: img('renew_portrait.svg'), $size: 20px, $position: center center);
                content: '';
                position: absolute;
                top: 5%;
                right: 1%;
                width: 20px;
                height: 20px;

            }
        }
    }

    .title-menu,
    .customer-logo,
    .user-menu {
        height: 100%;
        display: inline-block;
    }

    .title-menu {
        max-width: calc(100% - 60px - 60px - 60px);
        width: calc(100% - 60px - 60px - 60px);
        height: 60px;
        text-align-last: center;

        &.sync{
            &.offline{
                padding-left: 60px
            }
        }

        .page-title {
            @include font-size(s);
            @include text-ellipsis();
            height: 100%;
        }

        .sub-menu {
            @include display-flex();
            @include justify-content();
            @include align-items();
            @include flex-direction(column);
            height: 100%;
            cursor: pointer;

            .title {
                @include font-size(11px, $important: true);
                @include text-ellipsis();
                font-family: $text-light !important;
                text-transform: uppercase;
            }

            .sub-title {
                @include font-size(sm);
                @include background($image: img('down_caret_dark.svg'), $position: right center, $size: 8px);
                @include text-ellipsis();
                -webkit-appearance: none;
                -moz-appearance: none;
                padding-right: 15px;
                font-family: $text-bold;
                line-height: 25px;

                &.opened {
                    @include background($image: img('up_caret_neutro_s90.svg'), $position: right center, $size: 8px);
                }
            }
        }
    }

    .page-title {
        @include font-size(m, $important: true);
        line-height: 60px;
        height: fit-content !important;
        margin: 0 auto;
    }

    .customer-logo {
        @include display-flex();
        @include justify-content();
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        width: calc(100% - 60px - 60px - 20px);
        height: calc(#{$menu-top-height} - 10px - 10px) !important;
    }

    .user-avatar {
        padding: 60px / 2;
        min-width: 60px;
        width: 60px;
        height: 60px;
        padding: 5px;
        cursor: pointer;

        .main-img {
            width: 50px;
            height: 50px;
        }
    }

    .sync-icon {
        @include display-flex();
        @include background($image: img('offline/sync.svg'), $size: 50px, $position: center center);
        height: 60px;
        width: 60px;
        min-height: 60px;
        min-width: 60px;
        padding: 10px;
        cursor: pointer;
    }

    &.offline {
        .title-menu {
            @include text-ellipsis();
            max-width: calc(100% - 180px);
            width: calc(100% - 180px);
            padding-right: 60px;

            &.offline{
                padding-left: 0px;
            }

            &.sync{
                padding-right: 0px;
            }
        }
        .offline-icon {
            @include display-flex();
            @include background($image: img('offline/no_wifi.svg'), $size: 40px, $position: center center);
            height: 60px;
            width: 60px;
            min-height: 60px;
            min-width: 60px;
            padding: 10px;
        }
    }
    &.printer {
        .title-menu {
            @include text-ellipsis();
            max-width: calc(100% - 180px);
            width: calc(100% - 180px);
            padding-left: 60px;
        }
        .printer-icon {
            @include display-flex();
            @include background($image: img('print_portrait.svg'), $size: 60px, $position: center center);
            height: 60px;
            width: 60px;
            padding: 10px;
            margin-right: 0px;
            cursor: pointer;
            // animation: pulse 2s infinite;

            @keyframes pulse {
                0% {
                    transform: scale(0.95);
                    // box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
                }

                70% {
                    transform: scale(1);
                    // box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
                }

                100% {
                    transform: scale(0.95);
                    // box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
                }
            }
        }
    }
}
</style>
