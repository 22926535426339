<template>
    <div id="submenu-box" :class="[$mq, 'overlay']" @click.self="closeBox">
        <div class="menu">
            <div class="subtool" :class="{ selected: $route.path == '/config/location' }" @click="navTool('/config/location')">
                <div class="text">{{ $t('config.location.title') }}</div>
            </div>
            <div v-if="tools.includes(3)" class="subtool" :class="{ selected: $route.path == '/config/printer' }" @click="navTool('/config/printer')">
                <div class="text">{{ $t('config.printer.title') }}</div>
            </div>
            <div class="subtool" v-if="hasSensorTool" :class="{ selected: $route.path == '/config/sensconfig' }" @click="navTool('/config/sensconfig')">
                <div class="text">{{ $t('config.sensor.title') }}</div>
            </div>
            <div class="subtool" :class="{ selected: $route.path == '/config/dashboard' }" @click="navTool('/config/dashboard')">
                <div class="text">{{ $t('config.dashboard.title') }}</div>
            </div>
            <div class="subtool" v-if="canCreateUsers" :class="{ selected: $route.path == '/config/users' }" @click="navTool('/config/users')">
                <div class="text">{{ $t('config.users.title') }}</div>
            </div>
            <div class="subtool" :class="{ selected: $route.path == '/config/status' }" @click="navTool('/config/status')">
                <div class="text">{{ $t('config.status.title') }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {}
    },
    computed: {
        emp_id() {
            return this.$store.getters['loginUser/getLocalEmployee']
        },
        tools() {
            var visible = []
            const config = this.$store.getters['login/getConfig']
            if (config.tools) {
                visible = config.tools
            }
            return visible
        },
        hasSensorTool() {
            let tools = this.$store.getters['login/getConfig'].tools
            //if tools has number 13 then return true
            return tools.includes(13)
        },
        canCreateUsers() {
            return parseInt(this.$store.getters.getCanCreateUsers)
        }
    },
    methods: {
        // SUB-MENU ACTIONS
        navTool(action) {
            let self = this
            if (self.$store.getters['hasAccess'](self.currentEmployee().id, 0) || action == '/config/location' || action == '/release') {
                // self.$router.push(self.redirectPath(action));
                self.$store.commit('setSubMenuToolsExpanded', false)
                if (action == '/config/sensconfig') {
                    self.$router.push({ path: action, params: { page: 'sensconfig' } })
                } else {
                    self.$router.push(self.redirectPath(action)).catch((err) => {
                        // if (err.name == 'NavigationDuplicated') {
                        //     self.$router.push('/reset' + self.redirectPath(action))
                        // }
                    })
                }
            } else {
                this.$popup.login({
                    list: this.$store.getters['employee/getLoginUsers'](0),
                    callAfterAccess: function (response) {
                        if (response) {
                            // self.$router.push(self.redirectPath(action));
                            self.$router.push(self.redirectPath(action)).catch((err) => {
                                if (err.name == 'NavigationDuplicated') {
                                    self.$router.go(self.$router.history.current)
                                }
                            })
                            self.$popup.close()
                        }
                    }
                })
            }
        },

        // GENERAL METHODS
        closeBox() {
            this.$store.commit('setSubMenuToolsExpanded', false)
        },

        currentEmployee() {
            var emp_id = this.$store.getters['loginUser/getLocalEmployee']

            if (emp_id) {
                var emp = this.$store.getters['employee/getEmployee'](emp_id)
            } else {
                var emp = this.$store.getters['loginUser/getLocalLocation']
            }

            return emp
        },

        redirectPath(firstResult) {
            // console.log('redirect')
            return this.$root.redirectPath(firstResult)
        },

        toCurrentRoute() {
            let currentPath = this.$route.path
            let aux = this.$route.path.split('/')[1]
            let parentPath = typeof aux !== 'undefined' ? '/' + aux : false

            if (parentPath && parentPath != currentPath) {
                this.$router.push({ path: parentPath })
            }
        }
    }
}
</script>

// PORTRAIT STYLES
<style lang="scss">
#submenu-box.overlay.portrait {
    $menu-top-height: 60px;
    background-color: transparent;
    z-index: 50;
    height: calc(100% - #{$menu-top-height});
    height: 100%;
    width: 100vw;
    position: absolute;
    top: $menu-top-height;
    top: 0;
    right: 0;

    .menu {
        width: 100vw;
        // height: 100%;
        height: fit-content;
        max-height: calc(70vh);
        background-color: $default-bg-color;
        position: absolute;
        right: 0;
        top: calc(#{$menu-top-height} - 1px);
        z-index: 50;
        padding: 15px;

        .subtool {
            @include border-radius(3px);
            background-color: white;
            height: 50px;
            line-height: 50px;
            padding: 0 20px;
            margin-bottom: 10px;
            cursor: pointer;

            .text {
                @include font-size(s);
                @include text-ellipsis();
                color: $neutro-s70;
                font-family: $text-bold;
            }

            &.selected {
                background-color: $main;
                .text {
                    color: #fff;
                }
            }
        }
    }
}
</style>
