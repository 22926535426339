<template>
    <div id="update_app-container" :class="$mq">
        <div class="update-content">
            <div class="header-img">
                <img class="header-andy" src="~@/../public/img/alexhead_04.png" alt="andy-header" />
            </div>
            <div class="title-update">
                {{ $t('popup.error_device_network.title2') }}
            </div>
            <div class="title-description-update">
                {{ $t('login.errors.ip_out_of_range') }}
            </div>
            <div class="footer-actions-update">
                <div class="test-now" @click="test()">
                    {{ $t('popup.error_device_network.test') }}
                </div>
                <div class="go-logout" @click="logout()">
                    {{ $t('menu_aux.action.disconnect') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'

export default {
    props: {},
    data() {
        return {
            title: i18n.t('popup.update.title'),
            message: i18n.t('popup.update.message'),
            textDownload: i18n.t('popup.update.text_download')
        }
    },
    computed: {
        locationData() {
            return this.$store.getters['loginUser/getLocalLocation']
        }
    },
    methods: {
        logout() {
            var self = this
            // get changes to sync before ask for logout
            let changesToSyncronize = false
            this.$store.dispatch('getCountSyncItems').then((count) => {
                changesToSyncronize = count
                if (count > 0) {
                    self.$popup.confirm({
                        message: self.$tc('config.changes_sync') + ' ' + self.$tc('config.changes_sync_logout', changesToSyncronize, { number: changesToSyncronize }) + ' ' + self.$tc('config.changes_sync_logout2', changesToSyncronize) + ' ' + self.$tc('config.changes_sync_logout3'),
                        // title: self.$t('popup.confirm.title'),
                        textSave: self.$t('popup.confirm.stay_conected'),
                        textCancel: self.$t('popup.cancel.close_session'),
                        callSave: function () {
                            self.$popup.close()
                        },
                        callCancel: function () {
                            self.$store.dispatch('login/logout').then(function () {
                                if (false && process.env.VUE_APP_IS_APP == 'TRUE') {
                                    //CLEAN FOLDER WITH ALEX FILE PRINTER CONFIGURATION
                                    // window.resolveLocalFileSystemURL(
                                    //     cordova.file.externalRootDirectory + 'ALEX',
                                    //     function (fileSystem) {
                                    //         var reader = fileSystem.createReader()
                                    //         reader.readEntries(
                                    //             function (entries) {
                                    //                 entries.forEach((entry) => {
                                    //                     entry.remove(
                                    //                         function () {},
                                    //                         function (error) {}
                                    //                     )
                                    //                 })
                                    //                 self.$router.push('/login').then(function () {
                                    //                     location.reload()
                                    //                 })
                                    //             },
                                    //             function (err) {
                                    //                 log(err)
                                    //             }
                                    //         )
                                    //     },
                                    //     function (err) {
                                    //         log(err)
                                    //     }
                                    // )
                                } else {
                                    self.$router.push('/login').then(function () {
                                        location.reload()
                                    })
                                }
                            })
                        }
                    })
                }

                self.$popup.disconnect({
                    message: this.$t('login.logout_desc', { location: this.locationData.name }),
                    textSave: this.$t('popup.local_logout.text_save'),
                    textCancel: this.$t('popup.local_logout.text_cancel'),
                    callSave: function () {
                        // self.$router.push('/login')
                        self.$store.dispatch('login/logout').then(function () {
                            if (false && process.env.VUE_APP_IS_APP == 'TRUE') {
                                //CLEAN FOLDER WITH ALEX FILE PRINTER CONFIGURATION
                                // window.resolveLocalFileSystemURL(
                                //     cordova.file.externalRootDirectory + 'ALEX',
                                //     function (fileSystem) {
                                //         var reader = fileSystem.createReader()
                                //         reader.readEntries(
                                //             function (entries) {
                                //                 entries.forEach((entry) => {
                                //                     entry.remove(
                                //                         function () {},
                                //                         function (error) {}
                                //                     )
                                //                 })
                                //                 self.$router.push('/login').then(function () {
                                //                     location.reload()
                                //                 })
                                //             },
                                //             function (err) {
                                //                 log(err)
                                //             }
                                //         )
                                //     },
                                //     function (err) {
                                //         log(err)
                                //     }
                                // )
                            } else {
                                self.$router.push('/login').then(function () {
                                    location.reload()
                                })
                            }
                        })
                    }
                })
            })
        },
        test() {
            var self = this
            this.$overlay.loading()

            this.$store
                .dispatch('login/initLocation')
                .then(() => {
                    self.$overlay.hide()
                })
                .catch(() => {
                    self.$overlay.hide()
                })
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#update_app-container {
    @include display-flex();
    @include justify-content(center);
    width: 100%;
    height: 100%;

    .update-content {
        @include display-flex();
        @include flex-direction(column);
        @include justify-content(space-between);
        @include align-items(center);
        @include align-self(center);
        max-width: 600px;
        height: 100%;
        max-height: 700px;
        padding: 14px;

        .header-img {
            margin-top: 40px;
            .header-andy {
                max-width: 150px;
            }
        }

        .title-update {
            @include font-size(30px);
            color: $main-dark;
            font-family: $text-bold;
            text-align: center;
            line-height: 40px;
            margin-bottom: 10px;

            @include portrait-tablet {
                @include font-size(40px);
            }
        }
        .title-description-update {
            @include font-size(m);
            color: $inactive-s80;
            font-family: $text;
            text-align: center;
            margin-bottom: 10%;
            @include portrait-tablet {
                @include font-size(ml);
            }
        }
        .footer-actions-update {
            .test-now {
                @include font-size(m);
                @include border-radius(4px);
                background-color: $main-dark;
                color: #fff;
                font-family: $text-bold;
                padding: 25px 50px;
                @extend .interaction;
                text-align: center;

                @include portrait-tablet {
                    @include font-size(ml);
                }
            }

            .go-logout {
                @include font-size(m);
                @include border-radius(4px);
                background-color: $neutro-dark;
                color: #000;
                font-family: $text-bold;
                margin-top: 5px;
                padding: 25px 50px;
                @extend .interaction;
                text-align: center;

                @include portrait-tablet {
                    @include font-size(ml);
                }
            }
        }
    }
    &.landscape {
        .update-content {
            .header-img {
                margin-top: 0px;
                .header-andy {
                    max-width: 200px;
                }
            }

            .title-update {
                @include font-size(40px);
            }
            .title-description-update {
                @include font-size(26px);
                line-height: 30px;
            }
            .footer-actions-update {
                .test-now {
                    @include font-size(22px);
                    padding: 30px 60px;
                }

                .update-later {
                    @include font-size(22px);
                }
            }
        }
    }

    &.desktop {
        .update-content {
            .header-img {
                margin-top: 0px;
                .header-andy {
                    max-width: 200px;
                }
            }

            .title-update {
                @include font-size(40px);
            }
            .title-description-update {
                @include font-size(26px);
                line-height: 30px;
            }
            .footer-actions-update {
                .test-now {
                    @include font-size(l);
                    padding: 30px 60px;
                }

                .update-later {
                    @include font-size(l);
                }
            }
        }
    }
}
</style>
